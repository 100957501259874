import React from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import Layout from "../components/layout";
import "../components/player.css";

export default function Template({
  data, pageContext // this prop will be injected by the GraphQL query below.
}) {
  const { edges } = data.allTournamentsJson;
  let thisPlayer = pageContext.player;
  let totalPoints = 0;

  edges.forEach(({ node }) => {
    const {
      results
    } = node;
    results.forEach(( place ) => {
      if( place.name === thisPlayer ) {
        totalPoints += Number(place.points);
      }
    });
  });
  const tournaments = data.allTournamentsJson.edges.map(( el ) => {
    return el.node;
  });
  return (
    <Layout>
      <div className='body' style={{
        marginTop: 50,
      }}>
        <Link to='/standings/' className="back">Back</Link>
        <h2>{thisPlayer}</h2>
        <p>Total points: {totalPoints}</p>
        <div className='player-labels'>
          <span>Tournament</span>
          <span>Game</span>
          <span className='player-labels__scores'>Score</span>
          <span className='player-labels__scores'>Place</span>
        </div>
        {
          tournaments.map(( el, i ) => {
            return (
              <div key={i} className='player-row'>
                <span className='player-row__tournament'><Link to={`/tournament/${el.index}/`}>{el.name}</Link></span>
                <span className='player-row__game'>{el.game}</span>
                <span className='player-row__season'>Season {el.season}</span>
                {
                  el.results.map(( player ) => {
                    if(player.name === thisPlayer) {
                      return (
                        <div key={i} className='player-row__scores'>
                          <span className='player-row__scores-labels'>Points: </span><span>{player.points}</span>
                          <span className='player-row__scores-labels'>Score: </span><span>{player.place}</span>
                        </div>
                      );
                    }
                    return false;
                  })
                }
              </div>
            );
          })
        }
      </div>
    </Layout>
  );
}

Template.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object
};

export const pageQuery = graphql`
  query($player: String!) {
    allTournamentsJson(filter: {results: {elemMatch: {name: {eq: $player}}}}, sort: {fields: index, order: DESC}) {
      edges {
        node {
          name
          game
          index
          season
          results {
            name
            points
            team
            place
          }
        }
      }
    }
  }
`;